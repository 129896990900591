import React, {useContext, useEffect, forwardRef, useState, createRef, useRef, useMemo} from "react";
import api from "../api";
import {Formik} from "formik";
import pluralize from "pluralize";
import { Comment } from '@ant-design/compatible';
import {
    Card,
    Row,
    Col,
    Tooltip,
    Popconfirm,
    Descriptions,
    List,
    Button,
    message,
    Skeleton,
    Typography,
    Popover,
    Space,
    Empty,
    Tag,
} from "antd";
import {CommentOutlined, SaveOutlined} from "@ant-design/icons";
import {FormItem, Mentions} from "formik-antd";
import TimeAgo from "../helpers/TimeAgo";
import User, {UserMention} from "../helpers/User";
import {AppContext} from "../../contexts/AppContext";
import ReactMarkdown from 'react-markdown'
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {useSelectedAssetsState} from "../../contexts/SelectedAssetsContext";
import AssetBrowseImage from "../explore/AssetBrowseImage";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import {useNavigate, useLocation} from "react-router-dom-v5-compat";
import FileOutlined from "@ant-design/icons/lib/icons/FileOutlined";
import useConsumer from "../../channels/consumer";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import {useAssetsState} from "../../contexts/AssetsContext";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import HelpPopover from "../HelpPopover";
import {useDrop} from "react-dnd";
import useCurrentUser from "../helpers/useCurrentUser";
import {useTranslation} from "react-i18next";

export default ({what, visible, setCount})=>{
    const {t} = useTranslation();
    const {state} = useContext(AppContext);
    const {currentUser} = state;

    const {commentable_id: id , type} = what

    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState([]);

    const [loadedAssets, setLoadedAssets] = useState([])

    useEffect(()=>{
        if(!id || !visible) return;

        // Don't autofocus textarea:
        // const el = document.querySelectorAll("div.ant-mentions > textarea")[0]
        // el?.focus()

        setLoading(true)
        setComments([])

        api(`/api/comments`,{params:{id, type}}).then(res => {
            setLoading(false)
            setComments(res.data)
        })
    }, [id, type, visible])

    const consumer = useConsumer();
    const sub = useRef();

    // TODO: move to comment manager so new comments can alert when drawer is closed:
    if(!sub.current) {
        sub.current = consumer.subscriptions.create({channel: "CommentsChannel", id, type}, {
            received: (data) => {
                switch(data.type) {
                    case 'comment':
                        if(!_.find(comments, {id: data.comment.id})) {
                            if(data.comment.user.id != currentUser.id)
                                message.info(<>New comment from <User user={data.comment.user}/></>)

                            setComments(comments => [data.comment, ...comments]);
                            setCount && setCount(count => count + 1)
                        }
                        return

                    case 'commentRemoved':
                        console.log('removed', data.id)
                        setComments(comments => {
                            const existing = _.find(comments, {id: data.id})
                            return _.without(comments, existing)
                        });
                        setCount && setCount(count => count - 1)
                        return

                    case 'commentUpdated':
                        setComments(comments => {
                            const i = _.indexOf(comments.map(c => c.id), data.comment.id)
                            comments.splice(i, 1, data.comment);
                            return [...comments]
                        });
                        return
                }
            }
        });
    }

    useEffect(()=>{
        if(!visible) {
            sub.current?.unsubscribe()
            sub.current = null
        }
    }, [visible])

    return (
        <div className={'comment-thread'}>
            <CommentForm what={what} loadedAssets={loadedAssets} setLoadedAssets={setLoadedAssets}/>
            <Skeleton loading={loading} active>
                <List
                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('none-yet','None yet...')}/> }}
                    size={'small'}
                    header={`${comments.length} ${comments.length == 1 ? t('comment', 'Comment') : t('comments', 'Comments')}`}
                    itemLayout="horizontal"
                    dataSource={comments}
                    renderItem={item => <CommentItem item={item} loadedAssets={loadedAssets} setLoadedAssets={setLoadedAssets}/>}
                />
            </Skeleton>
        </div>
    )
}

const AssetMention = ({guid, loadedAssets=[], setLoadedAssets, style={}})=> {
    const {t} = useTranslation();
    const [loading, setLoading] = useState()
    const [error, setError] = useState()

    const [asset, setAsset] = useState(_.find(loadedAssets, a => a?.guid == guid || a?.guid?.substring(0,8) == guid))

    useEffect(()=>{
        // When using `setLoadedAssets` it's not expected for the asset to change: comment threads.
        // Otherwise in GUID entry input fields it needs to reload when GUId changes.
        if(!guid || (asset && setLoadedAssets) || loading) return

        setLoading(true)

        api(`/api/assets/${guid}`).then(res => {
            setLoading(false)
            setAsset(res.data)
            setLoadedAssets && setLoadedAssets(loadedAssets => _.find(loadedAssets, {id: res.data.id}) ? loadedAssets : [res.data, ...loadedAssets])
        }).catch(()=>{
            setError(true)
        })
    },[guid])

    const navigate = useNavigate();
    const location = useLocation()

    const onClick = ()=> {
        location.hash = `/assets/${guid}`
        navigate(location)
    }

    const size = 50
    const gridSize = 200

    if(error) return <>{guid}</>

    return asset && !loading ?
        <Popover
            zIndex={1033}
            title={<><FileOutlined/> {t('asset-details','Asset Details')}</>}
            content={
                <>
                    <img src={asset.grid_url} style={{display:'block', marginLeft:'auto', marginRight:'auto', height: gridSize, marginBottom:'.5em'}}/>
                    <Descriptions bordered size='small' column={1}>
                        <Descriptions.Item label={t('filename','Filename')}>{asset.filename}</Descriptions.Item>
                        <Descriptions.Item label={t('guid','Guid')}><Typography.Text code copyable>{asset.short_guid}</Typography.Text></Descriptions.Item>
                        <Descriptions.Item label={t('uploaded-by','Uploaded By')}><User user={asset.user}/> <TimeAgo date={asset.created_at}/></Descriptions.Item>
                    </Descriptions>
                </>
            }
        >
            <a onClick={onClick}>
                <span style={{border: '1px solid #ddd', height: size + 1, width: size + 1, display:'inline-block', ...style}}>
                    <AssetBrowseImage asset={asset} assetSize={size} thumbType={'thumb'} padding={'.5em'} forceGrid/>
                </span>
            </a>
            &nbsp;
        </Popover> :
        <LoadingOutlined/>
}

export {AssetMention}

const CommentForm = ({what={}, comment, onUpdate, loadedAssets, setLoadedAssets})=> {
    const {t} = useTranslation();
    const {commentable_id: id , type} = what

    const currentUser = useCurrentUser()

    let text = comment?.text

    return (
        (<Comment
            avatar={<User user={currentUser} iconOnly/>}
            content={
                <Formik
                    initialValues={{text}}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        const data = {comment: values, id, type}

                        if(comment) {
                            api.put(`/api/comments/${comment.id}`, data).then(res => {
                                actions.resetForm();
                                actions.setSubmitting(false);

                                message.success(t('message-comment-updated',`Comment updated.`))
                                onUpdate && onUpdate()
                            }).catch(()=>{
                                actions.setSubmitting(false);
                                message.error('Error saving comment.')
                            })

                        } else {
                            api.post(`/api/comments`, data).then(res => {
                                actions.resetForm();
                                actions.setSubmitting(false);

                                message.success(t('message-comment-added',`Comment added.`))
                            }).catch(()=>{
                                actions.setSubmitting(false);
                                message.error('Error saving comment.')
                            })
                        }
                    }}
                >
                    {({values, submitForm, handleSubmit, isSubmitting, setFieldValue}) => {

                        const [mentions, setMentions] = useState([])
                        const [mentionsLoading, setMentionsLoading] = useState()

                        const onSearch = search =>{
                            setMentionsLoading(true)
                            api('/api/memberships/search', {params:{q: search, commentable_id: id, commentable_type: type}}).then(res => {
                                setMentionsLoading(false)
                                setMentions(res.data)
                            }).catch(()=>{
                                setMentionsLoading(false)
                            })
                        }

                        const [preview, setPreview] = useState(false)

                        const clickPreview = ()=> {
                            setPreview(!preview)
                        }

                        const onSubmit = (e)=> {
                            setPreview(false)
                            submitForm(e)
                        }

                        //--------------------
                        // Asset Drop Handling
                        //--------------------
                        const {selectedAssetIds} = useSelectedAssetsState()
                        const {assets} = useAssetsState()

                        const [{canDrop, isOver}, drop] = useDrop({
                            accept: 'asset',
                            drop: ({asset}) => {
                                const selectedAssetGuids = _.filter(Object.values(assets), asset => selectedAssetIds.indexOf(asset.id) != -1).map(a => a.guid.substring(0,8))
                                console.log('dropped', selectedAssetGuids)
                                setFieldValue('text', (values.text || '') + ' ' + selectedAssetGuids?.join(' '))
                            },
                            collect: (monitor) => ({
                                isOver: monitor.isOver(),
                                canDrop: monitor.canDrop(),
                            })
                        });

                        return (
                            (<form onSubmit={onSubmit}>
                                {preview && (
                                    <CommentItem item={values} loadedAssets={loadedAssets} setLoadedAssets={setLoadedAssets} preview />
                                ) || (
                                    <Tooltip title={t('tooltip-drop-assets-to-mention','Drop assets here to mention.')} open={isOver}>
                                        <div ref={drop}>
                                            <FormItem name='text' style={{marginTop:'.5em'}} id={'comment-text-area'}>
                                                <Mentions
                                                    name='text'
                                                    style={{ width: '100%' }}
                                                    loading={mentionsLoading}
                                                    onSearch={onSearch}
                                                    rows={3}
                                                    placeholder={t('new-comment','New Comment...')}
                                                >
                                                    {mentions.map(m => (
                                                        <Mentions.Option key={m.id} value={m.username || m.name}>
                                                            <div id={`user-mention-${m.id}`} className={'user-mention'}>
                                                                {type == 'Lightbox' && !m.lightbox_member && (
                                                                    <Tooltip
                                                                        title={
                                                                            what.user?.id && what.user?.id === currentUser?.id ?
                                                                                t('at-mention-lightbox-note','@-mentioning this User will invite them to the Lightbox') :
                                                                                t('no-access-to-lightbox-note','This User will not be able to access the Lightbox')
                                                                        }
                                                                        placement={'topRight'}
                                                                    >
                                                                        <Tag>{t('not-a-member','Not a Member')}</Tag>
                                                                    </Tooltip>
                                                                )}

                                                                <User user={m.user}/>
                                                            </div>
                                                        </Mentions.Option>
                                                    ))}
                                                </Mentions>
                                            </FormItem>
                                        </div>
                                    </Tooltip>
                                )}
                                {comment && (
                                    <Row gutter={16}>
                                        <Col span={6}>
                                            <Button type={'primary'} onClick={onSubmit} loading={isSubmitting} block>
                                                <CommentOutlined/>
                                                {t('button-update','Update')}
                                            </Button>
                                        </Col>
                                        <Col span={6}>
                                            <Button onClick={onUpdate} block>
                                                <CloseCircleOutlined/>
                                                {t('button-cancel','Cancel')}
                                            </Button>
                                        </Col>

                                        <Col span={12}>
                                            {preview && (
                                                <Button onClick={clickPreview} block loading={isSubmitting}>
                                                    <EditOutlined/>
                                                    {t('button-edit','Edit')}
                                                </Button>

                                            ) || (
                                                <Button onClick={clickPreview} block loading={isSubmitting}>
                                                    <EyeOutlined/>
                                                    {t('button-preview','Preview')}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                ) || (
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Button type={'primary'} onClick={onSubmit} block loading={isSubmitting}>
                                                <CommentOutlined/>
                                                {t('button-add','Add')}
                                            </Button>
                                        </Col>

                                        <Col span={12}>
                                            {preview && (
                                                <Button onClick={clickPreview} block loading={isSubmitting}>
                                                    <EditOutlined/>
                                                    {t('button-edit','Edit')}
                                                </Button>

                                            ) || (
                                                <Button onClick={clickPreview} block loading={isSubmitting}>
                                                    <EyeOutlined/>
                                                    {t('button-preview','Preview')}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                <div style={{float:'right'}}>
                                    <small>
                                        <HelpPopover code={'comment-formatting'} generalAndAdmin title={t('formatting-help','Formatting Help')}/>
                                    </small>
                                </div>
                            </form>)
                        );
                    }}
                </Formik>
            }
        />)
    );
}

const CommentItem = ({item, loadedAssets, setLoadedAssets, preview}) => {
    const {t} = useTranslation();
    const {state} = useContext(AppContext);
    const {currentUser} = state;

    const renderers = {
        text: ({value})=>{
            return useMemo(()=>{
                const usernamePattern = "(@[A-Za-zÀ-ÖØ-öø-ÿ0-9_-]+?)( |$|,|;|-|!|\\?)"
                const assetPattern = "([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})\\b"
                const shortAssetPattern = "([0-9a-f]{8})\\b"
                const linkPattern = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})"
                // TODO: email pattern

                let parts = value.split(new RegExp(`${[usernamePattern, assetPattern, shortAssetPattern, linkPattern].join('|')}`,'gi'))

                for(let i = 1; i < parts.length; i += 1) {
                    if(parts[i]?.match(new RegExp(usernamePattern))) {
                        const username = parts[i].replace(/@/,'')
                        parts[i] = <UserMention username={username} key={`${username}-${i}`}/>

                    } else if(parts[i]?.match(new RegExp(linkPattern))) {
                        const url = parts[i]
                        parts[i] = <a href={url} target={'_blank'}>{url}</a>

                    } else if(parts[i]?.match(new RegExp(assetPattern))) {
                        const guid = parts[i]
                        parts[i] = <AssetMention guid={guid} key={`${guid}-${i}`} loadedAssets={loadedAssets} setLoadedAssets={setLoadedAssets}/>

                    } else if(parts[i]?.match(new RegExp(shortAssetPattern))) {
                        const shortGuid = parts[i]
                        parts[i] = <AssetMention guid={shortGuid} key={`${shortGuid}-${i}`} loadedAssets={loadedAssets} setLoadedAssets={setLoadedAssets}/>
                    }
                }

                return <>{parts}</>;

            }, [value]);
        }
    }

    const remove = ()=> {
        api.delete(`/api/comments/${item.id}`).then(res => {
            message.success(t('message-comment-removed','Comment removed.'))
        })
    }

    const [editing, setEditing] = useState()

    if(preview) return (
        <Card size={'small'} style={{marginBottom:'1em'}}>
            <ReactMarkdown renderers={renderers}>
                {item.text}
            </ReactMarkdown>
        </Card>
    )

    const actions = []
    if(item.user.id == currentUser.id) {
        actions.push(
            <span onClick={() => setEditing(true)}><EditOutlined/> {t('button-edit','Edit')}</span>
        )

        actions.push(
            <Popconfirm title={t('confirm-remove-comment','Remove Comment?')} onConfirm={remove}><DeleteOutlined/> {t('button-remove','Remove')}</Popconfirm>
        )
    }

    return (
        <List.Item key={item.id}>
            {editing && (
                <div style={{width:'100%'}}>
                    <CommentForm
                        comment={item}
                        onUpdate={()=> setEditing(false)}
                        loadedAssets={loadedAssets}
                        setLoadedAssets={setLoadedAssets}
                    />
                </div>
            ) || (
                <Comment
                    actions={actions}
                    author={item.user.name}
                    avatar={<User user={item.user} iconOnly/>}
                    content={
                        <ReactMarkdown renderers={renderers}>
                            {item.text}
                        </ReactMarkdown>
                    }
                    datetime={
                        <>
                            <TimeAgo date={item.created_at}/>
                            {item.edited_at && (
                                <Tooltip title={<>{t('edited','Edited')} <TimeAgo date={item.edited_at}/></>}>
                                    <EditOutlined/>
                                </Tooltip>
                            )}
                        </>
                    }
                />
            )}
        </List.Item>
    );
}